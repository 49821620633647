import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import JSConfetti from "js-confetti";

import {
  getStreakMultiplier,
  useIncrementPoints,
  useSetCorrectAnswer,
  useSetIncorrectAnswer,
  useUser,
} from "../../services/DbService";

import { usePersistedState } from "../../hooks/usePersistedState";

import { QUESTION_SET } from "../../constants/questionSet";

import Button, { ButtonColors, ButtonSizes } from "../../components/Button";
import Card from "../../components/Card";
import Heading, { HeadingLevels } from "../../components/Heading";
import Modal from "../../components/Modal";

import { getNewQuestionId } from "./Play";

import { ReactComponent as InputArrow } from "./InputArrow.svg";
import { ReactComponent as LightbulbIcon } from "../../assets/icons/32-lightbulb.svg";

import hintSound from "../../assets/sounds/hint.wav";
import wrongSound from "../../assets/sounds/wrong.mp3";

import styles from "./Play.module.scss";
import { NumberPill, PointsCoin } from "../../components/Number";

const HINT_YEAR = "year";
const HINT_GENRE = "genre";
const HINT_ACTOR = "actor";

const Hint = {
  [HINT_YEAR]: {
    title: "Release Year",
    cost: 20,
  },
  [HINT_GENRE]: {
    title: "Genre",
    cost: 40,
  },
  [HINT_ACTOR]: {
    title: "Lead Actor",
    cost: 60,
  },
};

// Temporary function that will eventually be replaced with something more complex
function checkAnswer(questionId, givenAnswer = "") {
  const { name, answers = [] } = QUESTION_SET[questionId];

  const trimmedAnswer = givenAnswer.trim();

  // Simple one-for-one check against the exact name before going through the array of potential answers
  if (RegExp(name, "i").test(trimmedAnswer)) return true;

  // Check all of the possible options for the given answer. If answers is empty and the simple
  // equality check above is false, this will also return false
  return answers.some((answer) => RegExp(answer, "i").test(trimmedAnswer));
}

function HintCard({ className, hint, slug, unlocked, onUnlock, ...props }) {
  const { points } = useUser();

  const { title, cost } = Hint[slug];

  const handleRequest = useCallback(() => {
    if (cost > points) return;

    onUnlock(slug, cost);
  }, [cost, points, onUnlock, slug]);

  if (!hint) return null;

  return (
    <Card className={clsx(className, styles.Hint)} center tag="p" {...props}>
      <span className={styles.HintName}>{title}</span>
      {unlocked ? (
        <Heading
          tag="span"
          className={styles.HintText}
          level={HeadingLevels.H3}
        >
          {hint}
        </Heading>
      ) : (
        <Button
          className={styles.HintButton}
          onClick={handleRequest}
          disabled={cost > points}
          sound={hintSound}
        >
          <PointsCoin />
          {cost}
        </Button>
      )}
    </Card>
  );
}

function PlayQuestion({ questionId, setQuestionId, onNext }) {
  const confettiCanvas = useMemo(() => new JSConfetti(), []);

  const inputRef = useRef();

  const { streak, audio } = useUser();

  const [answerValue, setAnswerValue] = useState("");
  const [hintModalOpen, setHintModalOpen] = useState(false);
  const [skipModalOpen, setSkipModalOpen] = useState(false);
  const [animatingWrong, setAnimatingWrong] = useState(false);
  const [incorrectCount, setIncorrectCount] = useState(0);

  const [hintYear, setHintYear] = usePersistedState("hintYear", false);
  const [hintGenre, setHintGenre] = usePersistedState("hintGenre", false);
  const [hintActor, setHintActor] = usePersistedState("hintActor", false);

  useEffect(() => {
    if (!audio || !animatingWrong) return;

    audio.sfx && new Audio(wrongSound).play();
    audio.haptic && navigator.vibrate(20);
  }, [audio, animatingWrong]);

  const onModalClose = useCallback(() => {
    inputRef.current.focus();
  }, []);

  // Very temporary useEffect to hide modals when a user presses escape. Currently there
  // are no instances of modals outside of this component, and none that stack on top
  // of each other so this is sufficient for the MVP as it satisfies the "Escape closes
  // modals" behaviour expected of an accessible web app. Longer term, it would be better
  // to pass onClose or something similar to the Modal component and allow it to handle
  // its own closure
  useEffect(() => {
    if (!hintModalOpen && !skipModalOpen) return;

    function handleKeyDown(e) {
      if (e.key !== "Escape") return;
      setHintModalOpen(false);
      setSkipModalOpen(false);
      console.log(inputRef.current);
      onModalClose();
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [hintModalOpen, skipModalOpen, onModalClose]);

  const hintSetters = useMemo(
    () => ({
      [HINT_YEAR]: setHintYear,
      [HINT_ACTOR]: setHintActor,
      [HINT_GENRE]: setHintGenre,
    }),
    [setHintYear, setHintActor, setHintGenre]
  );

  const {
    year,
    genre,
    actor,
    labels: emojiLabels,
    strings: emojiStrings,
  } = QUESTION_SET[questionId];

  const streakMutiplier = getStreakMultiplier(streak);

  const setCorrectAnswer = useSetCorrectAnswer();
  const setIncorrectAnswer = useSetIncorrectAnswer();
  const incrementPoints = useIncrementPoints();

  const goToNextQuestion = useCallback(
    (isCorrect) => {
      const newQuestionId = getNewQuestionId(questionId);
      setQuestionId(newQuestionId);
      setAnswerValue("");
      for (const hintSetter of Object.values(hintSetters)) {
        hintSetter(false);
      }
      onNext && onNext(isCorrect, questionId);
    },
    [questionId, setQuestionId, onNext, hintSetters]
  );

  const handleChange = useCallback((e) => {
    setAnswerValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!answerValue) {
        setAnimatingWrong(true);
        return;
      }

      const isCorrect = checkAnswer(questionId, answerValue);

      if (isCorrect) {
        setCorrectAnswer(incorrectCount === 0);
        goToNextQuestion(true);
        setIncorrectCount(0);

        confettiCanvas.addConfetti({
          emojis: emojiStrings,
          emojiSize: 75,
          confettiNumber: 15,
        });
        confettiCanvas.addConfetti({
          confettiColors: JSON.parse(styles.confetti).split(","),
        });
      } else {
        setIncorrectCount((currentIncorrectCount) => currentIncorrectCount + 1);
        setAnimatingWrong(true);
        setAnswerValue("");

        audio.sfx && new Audio(wrongSound).play();
        audio.haptic && navigator.vibrate(20);
      }
    },
    [
      questionId,
      answerValue,
      setCorrectAnswer,
      incorrectCount,
      goToNextQuestion,
      audio,
      confettiCanvas,
      emojiStrings,
    ]
  );

  const handleShowSkip = useCallback(() => {
    setSkipModalOpen(true);
  }, []);

  const handleCloseSkip = useCallback(() => {
    setSkipModalOpen(false);
    onModalClose();
  }, [onModalClose]);

  const handleSkip = useCallback(() => {
    setSkipModalOpen(false);
    setIncorrectAnswer();
    goToNextQuestion(false);
  }, [setIncorrectAnswer, goToNextQuestion]);

  const handleShowHint = useCallback(() => {
    setHintModalOpen(true);
  }, []);

  const handleCloseHint = useCallback(() => {
    setHintModalOpen(false);
    onModalClose();
  }, [onModalClose]);

  const handleUnlockHint = useCallback(
    (hintSlug, cost) => {
      hintSetters[hintSlug](true);
      incrementPoints(cost * -1);
    },
    [hintSetters, incrementPoints]
  );

  const showHintButton = year || genre || actor;

  return (
    <>
      <Heading className={clsx("mb2", styles.Title)} center>
        Guess the Movie!
      </Heading>

      <Card className={styles.QuestionCard}>
        {streak > 0 && (
          <NumberPill className={styles.QuestionStreak} number={streak}>
            {Array(streakMutiplier)
              .fill("")
              .map(() => "🔥")}
          </NumberPill>
        )}
        <p
          className={styles.QuestionEmojis}
          aria-label={`Emojis: ${emojiLabels.join(", ")}`}
          data-emoji-count={emojiStrings.length}
        >
          {emojiStrings.map((emoji, emojiIndex) => (
            <span key={emojiIndex}>{emoji}</span>
          ))}
        </p>
      </Card>

      <div className={clsx("mt2", styles.Fieldset)}>
        <Button
          className={styles.FieldsetHint}
          onClick={handleShowHint}
          disabled={!showHintButton}
          aria-label="Hint"
          iconOnly
        >
          <LightbulbIcon role="presentation" />
        </Button>
        <form className={styles.Form} onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            aria-label="Answer"
            className={clsx(
              styles.FormInput,
              animatingWrong && styles["FormInput-wrong"]
            )}
            onAnimationEnd={() => setAnimatingWrong(false)}
            onChange={handleChange}
            value={answerValue}
            placeholder="Name the film…"
            autoFocus
          />
          <button className={styles.FormButton} type="submit">
            <span className="sr-only">Submit</span>
            <InputArrow role="presentation" />
          </button>
        </form>

        <div className={styles.Buttons}>
          <Button color={ButtonColors.LIGHT_OUTLINE} onClick={handleShowSkip}>
            Skip Question
          </Button>
        </div>
      </div>

      {hintModalOpen && (
        <Modal onClose={handleCloseHint} closeLabel="Go back">
          <Heading center>Need Help?</Heading>
          <p className={styles.HintIntro}>
            If you're stuck, spend some points to unlock hints to help&nbsp;you!
          </p>
          <HintCard
            slug={HINT_YEAR}
            hint={year}
            unlocked={hintYear}
            onUnlock={handleUnlockHint}
          />
          <HintCard
            slug={HINT_GENRE}
            hint={genre && genre.join(", ")}
            unlocked={hintGenre}
            onUnlock={handleUnlockHint}
          />
          <HintCard
            slug={HINT_ACTOR}
            hint={actor}
            unlocked={hintActor}
            onUnlock={handleUnlockHint}
          />
        </Modal>
      )}

      {skipModalOpen && (
        <Modal onClose={handleCloseSkip} closeLabel="Cancel">
          <Heading center>Are you sure?</Heading>
          <Card className="mt2" center>
            <p>
              If you skip this question, you will lose your streak, but you will
              lose no&nbsp;points.
            </p>
          </Card>
          <Button
            className="mt"
            block
            onClick={handleSkip}
            size={ButtonSizes.LARGE}
            sound={false}
          >
            Skip
          </Button>
        </Modal>
      )}
    </>
  );
}

export default PlayQuestion;
