import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";
import clsx from "clsx";

import * as serviceWorker from "../../serviceWorkerRegistration";

import { Routes } from "../../constants/routes";

import { UserContextProvider, useUser } from "../../services/DbService";

import Button, { ButtonColors } from "../../components/Button";
import Container from "../../components/Container";
import { NumberPill, PointsCoin } from "../../components/Number";

import Home from "../Home";
import Play from "../Play";
import Profile from "../Profile";
import Styleguide from "../Styleguide";

import { ReactComponent as IconBack } from "../../assets/icons/16-back.svg";
import { ReactComponent as IconPerson } from "../../assets/icons/16-person.svg";

import styles from "./App.module.scss";

export const PRODUCT_NAME = "Sharades";

function Nav() {
  const user = useUser();
  const isPlayRoute = useRouteMatch(Routes.PLAY);
  const isHomeRoute = useRouteMatch(Routes.HOME).isExact;

  const { points } = user;

  const [navUrl, navLabel, navIcon] = useMemo(() => {
    if (isPlayRoute) {
      return [Routes.PROFILE, "Profile", <IconPerson role="presentation" />];
    }
    if (!isHomeRoute) {
      return [
        Routes.PLAY,
        user ? "Resume" : "Start Game",
        <IconBack role="presentation" />,
      ];
    }
    return [];
  }, [isPlayRoute, user, isHomeRoute]);

  if (!navUrl) return null;

  return (
    <>
      <div className={styles.Points}>
        <Container className={styles.NavContainer}>
          <NumberPill className={styles.PointsPill} number={points}>
            <PointsCoin />
          </NumberPill>
        </Container>
      </div>
      <nav className={styles.Nav}>
        <Container className={styles.NavContainer}>
          <Button
            className={styles.NavButton}
            to={navUrl}
            color={ButtonColors.LIGHT_OUTLINE}
            iconOnly
            aria-label={navLabel}
          >
            {navIcon}
          </Button>
        </Container>
      </nav>
    </>
  );
}

function App() {
  const user = useUser();
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onServiceWorkerUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    // Learn more about service workers: https://cra.link/PWA
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
  }, []);

  const handleUpdateApp = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
  }, [waitingWorker]);

  // User hasn't loaded, show the loading screen
  if (user === null) {
    return <p className={styles.Loader}>Loading…</p>;
  }

  return (
    <Router>
      {process.env.NODE_ENV === "development" && (
        <button
          style={{
            position: "fixed",
            top: "env(safe-area-inset-top)",
            left: "5px",
          }}
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      )}
      <Nav />
      <Switch>
        <Route exact path={Routes.HOME}>
          <Home />
        </Route>
        <Route exact path={Routes.PLAY}>
          <Play />
        </Route>
        <Route exact path={Routes.PROFILE}>
          <Profile />
        </Route>
        {/* TODO: Hide on production */}
        <Route exact path={Routes.STYLEGUIDE}>
          <Styleguide />
        </Route>
      </Switch>

      <div
        className={clsx(
          styles.UpdateToast,
          showReload && styles["UpdateToast-active"]
        )}
      >
        <Button onClick={handleUpdateApp}>Update Available</Button>
      </div>
    </Router>
  );
}

function AppWithContext() {
  return (
    <UserContextProvider>
      <App />
    </UserContextProvider>
  );
}

export default AppWithContext;
