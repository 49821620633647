import { Fragment, useState } from "react";

import { formatPoints } from "../../utils/formatPoints";

import Button, { ButtonSizes, ButtonColors } from "../../components/Button";
import Container from "../../components/Container";
import Heading, { HeadingLevels } from "../../components/Heading/Heading";
import { NumberPill, PointsCoin } from "../../components/Number";

import styles from "./Styleguide.module.scss";

function Styleguide() {
  const [demoPoints, setDemoPoints] = useState(0);

  return (
    <Container tag="main">
      <Heading level={HeadingLevels.H1}>Styleguide</Heading>

      {/* Headings */}
      <Heading>Headings</Heading>
      <div className={styles.Headings}>
        <Heading level={HeadingLevels.H1}>Heading Level&nbsp;1</Heading>
        <Heading level={HeadingLevels.H2}>Heading Level&nbsp;2</Heading>
        <Heading level={HeadingLevels.H3}>Heading Level&nbsp;3</Heading>
        <Heading level={HeadingLevels.H4}>Heading Level&nbsp;4</Heading>
        <Heading level={HeadingLevels.H2} tag="h3">
          Heading Level 2 (as an &lt;h3&gt;)
        </Heading>
      </div>

      {/* Buttons */}
      <Heading className={styles.SectionTitle}>Buttons</Heading>
      {Object.values(ButtonColors).map((buttonColor) => (
        <Fragment key={buttonColor}>
          <Heading level={HeadingLevels.H3}>
            Color: <code>{buttonColor}</code>
          </Heading>
          <div className={styles.Buttons}>
            <Button color={buttonColor}>Default</Button>
            <Button color={buttonColor} disabled>
              Disabled
            </Button>
            <Button color={buttonColor} loading>
              Loading
            </Button>
            <Button color={buttonColor} block>
              Block
            </Button>
          </div>
        </Fragment>
      ))}
      {Object.values(ButtonSizes).map((buttonSize) => (
        <Fragment key={buttonSize}>
          <Heading level={HeadingLevels.H3}>
            Size: <code>{buttonSize}</code>
          </Heading>
          <div className={styles.Buttons}>
            <Button size={buttonSize}>Default</Button>
            <Button size={buttonSize}>
              Multi-
              <br />
              line
            </Button>
          </div>
        </Fragment>
      ))}

      {/* Miscellaneous */}
      <Heading className={styles.SectionTitle}>Miscellaneous</Heading>
      <Heading level={HeadingLevels.H3}>Number Pill</Heading>
      <div className={styles.Buttons}>
        <NumberPill number={demoPoints} />
        <NumberPill number={demoPoints}>🔥</NumberPill>
        <NumberPill number={demoPoints}>
          <PointsCoin />
        </NumberPill>
      </div>
      <br />
      <div className={styles.Buttons}>
        {[10, 100, 1_000, 10_000, 100_000, 1_000_000].map((increase) => (
          <Button
            key={increase}
            color={ButtonColors.LIGHT_OUTLINE}
            size={ButtonSizes.SMALL}
            onClick={() => setDemoPoints((p) => p + increase)}
          >
            +{formatPoints(increase)}
          </Button>
        ))}
        <Button
          color={ButtonColors.LIGHT_OUTLINE}
          size={ButtonSizes.SMALL}
          onClick={() => setDemoPoints(0)}
        >
          Reset
        </Button>
      </div>
    </Container>
  );
}

export default Styleguide;
