import { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { randomItemFromArray } from "../../utils/randomItemFromArray";

import { usePersistedState } from "../../hooks/usePersistedState";

import { QUESTION_SET } from "../../constants/questionSet";

import Button, { ButtonSizes } from "../../components/Button";
import Container from "../../components/Container";
import Heading, { HeadingLevels } from "../../components/Heading";

import PlayQuestion from "./PlayQuestion";

import styles from "./Play.module.scss";

const QuestionState = {
  QUESTION: "question",
  CORRECT: "correct",
  INCORRECT: "incorrect",
};

const StateHeadings = {
  [QuestionState.CORRECT]: ["Congrats!", "Wahoo!", "Yasss!", "Yup!"],
  [QuestionState.INCORRECT]: [
    "Uh oh!",
    "Nope!",
    "Not this time!",
    "Eeesh!",
    "Oooft!",
    "You didn’t…?",
  ],
};

export function getNewQuestionId(currentQuestionId = "") {
  const newQuestionId = randomItemFromArray(Object.keys(QUESTION_SET));

  // Ensure that the same question isn't shown back-to-back
  // This isn't the best way of doing it as there's nothing to stop this infinitely
  // finding the same ID, but with a big enough dataset it should be fine.
  // Long-term a better randomiser would ensure that a user isn't shown the same
  // question for a while.
  if (newQuestionId === currentQuestionId) {
    return getNewQuestionId(currentQuestionId);
  }

  return newQuestionId;
}

function Play() {
  const nextButtonRef = useRef();

  const [questionState, setQuestionState] = useState(QuestionState.QUESTION);
  const [previousAnswer, setPreviousAnswer] = useState("");
  const [stateHeading, setStateHeading] = useState("");

  const [questionId, setQuestionId] = usePersistedState(
    "questionId",
    getNewQuestionId()
  );

  useEffect(() => {
    if (questionState === QuestionState.QUESTION) return;

    nextButtonRef.current.focus();
  }, [questionState]);

  const handleNext = useCallback((isCorrect, previousQuestionId) => {
    const { name, year } = QUESTION_SET[previousQuestionId];

    const nextState = isCorrect
      ? QuestionState.CORRECT
      : QuestionState.INCORRECT;
    setPreviousAnswer(year ? `${name} (${year})` : name);
    setStateHeading(randomItemFromArray(StateHeadings[nextState]));
    setQuestionState(nextState);
  }, []);

  return (
    <Container
      className={clsx(styles.Wrapper, styles[`Wrapper-${questionState}`])}
      tag="main"
    >
      {questionState === QuestionState.QUESTION ? (
        <PlayQuestion
          questionId={questionId}
          setQuestionId={setQuestionId}
          onNext={handleNext}
        />
      ) : (
        <>
          <Heading level={HeadingLevels.H1}>{stateHeading}</Heading>
          <Heading level={HeadingLevels.H4}>The answer was</Heading>
          <Heading className={styles.StateAnswer}>{previousAnswer}</Heading>
          <Button
            ref={nextButtonRef}
            className="mt2"
            onClick={() => setQuestionState(QuestionState.QUESTION)}
            size={ButtonSizes.LARGE}
            block
          >
            Next Question
          </Button>
        </>
      )}
    </Container>
  );
}

export default Play;
