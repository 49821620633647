import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import { formatPoints } from "../../utils/formatPoints";

import styles from "./Number.module.scss";

export function PointsCoin(props) {
  return <span className={styles.Coin} aria-hidden="true" {...props} />;
}

export function NumberPill({ className, children, units, number, ...props }) {
  const initRef = useRef(null);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      return;
    }
    setAnimating(true);
  }, [number]);

  return (
    <abbr
      className={clsx(
        className,
        styles.Pill,
        animating && styles["NubmerPill-animating"]
      )}
      onAnimationEnd={() => setAnimating(false)}
      {...props}
    >
      {children}
      <Number number={number} />
    </abbr>
  );
}

function Number({ number }) {
  const [stringWithCommas, numbers, commas] = useMemo(() => {
    const stringWithCommas = formatPoints(number);

    const commas = stringWithCommas.split(",").length - 1;
    const numbers = stringWithCommas.length - commas;

    return [stringWithCommas, numbers, commas];
  }, [number]);

  return (
    <span
      className={styles.Number}
      style={{
        "--points-numbers": numbers,
        "--points-commas": commas,
      }}
    >
      {stringWithCommas}
    </span>
  );
}

export default Number;
