import { useEffect, useRef } from "react";
import clsx from "clsx";

import Button, { ButtonColors } from "../Button";
import Container, { ContainerWidth } from "../Container";

import styles from "./Modal.module.scss";

function Modal({
  className,
  children,
  onClose,
  containerWidth = ContainerWidth.THIN,
  closeLabel = "Close",
  ...props
}) {
  const modalRef = useRef();

  useEffect(() => {
    modalRef.current.focus();
  }, []);

  return (
    <div
      ref={modalRef}
      className={clsx(className, styles.Wrapper)}
      tabIndex={-1}
      {...props}
    >
      <Container className={styles.Container} width={containerWidth}>
        {children}
      </Container>
      {onClose && (
        <div className={styles.Buttons}>
          <Button
            className={styles.Close}
            onClick={onClose}
            color={ButtonColors.LIGHT_OUTLINE}
            backdropBlur={false}
          >
            {closeLabel}
          </Button>
        </div>
      )}
    </div>
  );
}

export default Modal;
