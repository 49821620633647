import { Routes } from "../../constants/routes";

import { useUser } from "../../services/DbService";

import Button, { ButtonSizes } from "../../components/Button";
import Container, { ContainerWidth } from "../../components/Container";
import Heading, { HeadingLevels } from "../../components/Heading";
import { PRODUCT_NAME } from "../App";

import posterImage from "../../assets/images/poster@2x.png";

import styles from "./Home.module.scss";

function Home() {
  const user = useUser();

  return (
    <Container
      className={styles.Wrapper}
      tag="main"
      center
      width={ContainerWidth.THIN}
    >
      <div className={styles.Poster} aria-hidden="true">
        <img
          src={posterImage}
          alt="Movie poster mash up"
          width={282}
          height={328}
        />
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </div>
      <Heading className={styles.Title} level={HeadingLevels.H1}>
        {PRODUCT_NAME}
      </Heading>
      <p className={styles.Intro}>
        Guess the movies from the emojis, build a streak and earn&nbsp;points!
      </p>
      <Button block to={Routes.PLAY} size={ButtonSizes.LARGE}>
        {user.streak ? "Resume Game" : "Start Playing"}
      </Button>
    </Container>
  );
}

export default Home;
