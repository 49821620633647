export const Genre = {
  ADVENTURE: "Adventure",
  CHILD: "Children",
  CRIME: "Crime",
  DRAMA: "Drama",
  FANTASY: "Fantasy",
  ROM_COM: "Romantic Comedy",
  SCI_FI: "Science Fiction",
  THRILLER: "Thriller",
  SUPERHERO: "Superhero",
};

export const QUESTION_SET = {
  "919405bd-3e7d-4561-ba86-0c3157dcb741": {
    strings: ["👦🏻", "👓", "⚡", "🏆", "🔥"],
    labels: ["Boy", "Glasses", "Lightning bolt", "Trophy", "Flames"],
    answers: [/^Harry\sPotter\s(\+|&|and)\sthe\sGoblet\sof\sFire$/],
    name: "Harry Potter and the Goblet of Fire",
    year: 2005,
    actor: "Daniel Radcliffe",
    genre: [Genre.FANTASY, Genre.CHILD],
  },
  "c19b30f0-2f99-439b-b070-934412cc7f7b": {
    strings: ["🕰️", "🚀", "🕳️"],
    labels: ["Time", "Rocket", "Black hole"],
    name: "Interstellar",
    year: 2014,
  },
  "bbc073a6-44a9-44b7-9387-86633e9714d5": {
    strings: ["🦖", "🏞️"],
    labels: ["Dinosaur", "Park"],
    answers: [/^Jurr?ass?ic\sPark/],
    name: "Jurassic Park",
    year: 1993,
    actor: "Sam Neill",
  },
  "fcc47213-32dc-4f49-b2a1-c48d47f747cf": {
    strings: ["🦖", "🌍"],
    labels: ["Dinosaur", "Earth"],
    answers: [/^Jurass?ic\sWorld/],
    name: "Jurassic World",
    year: 2015,
  },
  "fa398189-2034-45c3-b8ab-75152a5f7fcc": {
    strings: ["🦇", "👨🏻", "➕", "🐦"],
    labels: ["Bat", "Man", "Plus", "Bird"],
    answers: [/^Batman\s(\+|&|and)\sRobin/],
    name: "Batman and Robin",
    year: 1997,
    actor: "George Clooney",
    genre: [Genre.SUPERHERO],
  },
  "ac31a77a-ad6a-4798-b0a0-65976ac9942f": {
    strings: ["🃏"],
    labels: ["Joker Card"],
    name: "Joker",
    year: 2019,
    actor: "Joaquin Phoenix",
    genre: [Genre.CRIME, Genre.DRAMA, Genre.THRILLER],
  },
  "072d0821-2f4b-4764-8951-f38136202c7d": {
    strings: ["💍", "💍", "💍", "💍", "⚰️"],
    labels: [
      "Engagement Ring",
      "Engagement Ring",
      "Engagement Ring",
      "Engagement Ring",
      "Coffin",
    ],
    answers: [/^(4|Four)\sWeddings\s(\+|&|and)\sa\sFuneral$/],
    name: "Four Weddings and a Funeral",
    year: 1994,
    actor: "Hugh Grant",
    genre: [Genre.ROM_COM],
  },
  "1dc62b0e-138a-4d6f-97b6-bdd1dcd2c7cc": {
    strings: ["🔎", "🐠"],
    labels: ["Magnifying Glass", "Fish"],
    name: "Finding Nemo",
    year: 2003,
  },
  "4f32bc1a-c7de-44db-94dd-c124815354b3": {
    strings: ["🧒🏽", "🐻", "🐆", "📗"],
    labels: ["Boy", "Bear", "Panther", "Book"],
    name: "Jungle Book",
    year: 1967,
    genre: [Genre.CHILD, Genre.ADVENTURE],
  },
  "3fc9564d-00dc-4167-a08f-b90690550520": {
    strings: ["👨‍🚀", "🤠", "📙"],
    labels: ["Astronaut", "Cowboy", "Book"],
    name: "Toy Story",
    year: 1995,
  },
  "a41e39f1-0b76-439c-a0b7-3f65616a198f": {
    strings: ["⭐", "⚔️", "📈", "🌥️", "🚶🏼‍♂️"],
    labels: [
      "Star",
      "Clashing Swords",
      "Increasing Chart",
      "Cloud",
      "Walking Man",
    ],
    answers: [
      /^Star\sWars:?\sEpisode\s(9|IX|Nine)$/,
      /^(Star\sWars:?\s)?The\sRise\sof\sSkywalker/,
    ],
    name: "Star Wars: The Rise of Skywalker (Episode IX)",
    year: 2019,
    actor: "Carrie Fisher",
    genre: [Genre.SCI_FI],
  },
};
