import clsx from "clsx";

import styles from "./Container.module.scss";

export const ContainerWidth = {
  THIN: "thin",
  DEFAULT: "default",
};

function Container({
  className,
  children,
  width = ContainerWidth.DEFAULT,
  tag = "div",
  center,
  ...props
}) {
  const ContainerTag = tag;

  return (
    <ContainerTag
      className={clsx(
        className,
        styles.Container,
        styles[`Container-${width}Width`],
        center && styles["Container-center"]
      )}
      {...props}
    >
      {children}
    </ContainerTag>
  );
}

export default Container;
