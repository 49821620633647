import clsx from "clsx";

import styles from "./Heading.module.scss";

export const HeadingLevels = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
};

function Heading({
  className,
  children,
  level = HeadingLevels.H2,
  center,
  tag,
  ...props
}) {
  const isH1 = level === HeadingLevels.H1;

  if (isH1 && typeof children !== "string") {
    process.env.NODE_ENV === "development" &&
      console.warn(
        "Heading – Invalid children, must be single string",
        children
      );
    return null;
  }

  const HeadingTag = tag || level;

  return (
    <HeadingTag
      className={clsx(
        className,
        styles.Heading,
        styles[`Heading-${level}`],
        center && styles["Heading-center"]
      )}
      aria-label={isH1 ? children : undefined}
      {...props}
    >
      {children}
    </HeadingTag>
  );
}

export default Heading;
