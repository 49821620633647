import { useCallback } from "react";
import { useHistory, Link } from "react-router-dom";

import { Routes } from "../../constants/routes";

import { formatPoints } from "../../utils/formatPoints";

import {
  AudioEnum,
  useResetUser,
  useToggleAudio,
  useUser,
} from "../../services/DbService";

import Button, { ButtonColors } from "../../components/Button";
import Card from "../../components/Card";
import Container from "../../components/Container";
import Heading, { HeadingLevels } from "../../components/Heading/Heading";

import styles from "./Profile.module.scss";
import clsx from "clsx";

function ProfileStat({ number, label }) {
  return (
    <Card className={styles.Stat} tag="p">
      <Heading
        className={styles.StatNumber}
        level={HeadingLevels.H2}
        tag="span"
      >
        {number}
      </Heading>
      <span className={styles.StatLabel}>{label}</span>
    </Card>
  );
}

function Profile() {
  const history = useHistory();

  const { name, correct, incorrect, points, streakHiscore, audio } = useUser();
  const resetUser = useResetUser();
  const toggleAudio = useToggleAudio();

  const handleResetUser = useCallback(() => {
    if (!window.confirm("Are you sure? This cannot be undone.")) return;

    resetUser();
    history.push(Routes.HOME);
  }, [resetUser, history]);

  const handleToggleMusic = useCallback(() => {
    toggleAudio(AudioEnum.MUSIC);
  }, [toggleAudio]);

  const handleToggleSfx = useCallback(() => {
    toggleAudio(AudioEnum.SFX);
  }, [toggleAudio]);

  const handleToggleHaptic = useCallback(() => {
    toggleAudio(AudioEnum.HAPTIC);
  }, [toggleAudio]);

  return (
    <Container className={styles.Wrapper} tag="main">
      <Heading level={HeadingLevels.H1} center>
        {name}
      </Heading>
      <div className={styles.Stats}>
        <ProfileStat number={correct} label="Correct" />
        <ProfileStat number={incorrect} label="Skips" />
        <ProfileStat number={streakHiscore} label="Longest Streak" />
        <ProfileStat number={formatPoints(points)} label="Points" />
      </div>
      <div className={clsx("mt2", styles.Buttons)}>
        <Button onClick={handleToggleMusic} disabled>
          Music <strong>{audio.music ? "ON" : "OFF"}</strong>
        </Button>
        <Button onClick={handleToggleSfx} sound={false}>
          SoundFX <strong>{audio.sfx ? "ON" : "OFF"}</strong>
        </Button>
        <Button onClick={handleToggleHaptic} sound={false}>
          Vibrations <strong>{audio.haptic ? "ON" : "OFF"}</strong>
        </Button>
      </div>
      <Button
        className="mt2"
        onClick={handleResetUser}
        color={ButtonColors.LIGHT_OUTLINE}
      >
        Reset User
      </Button>

      {!["staging", "prod"].includes(process.env.REACT_APP_CONTEXT) && (
        <p className="mt2 mb0">
          <strong>Route debugger: </strong>
          <Link to={Routes.HOME}>Home</Link>{" "}
          <Link to={Routes.STYLEGUIDE}>Styleguide</Link>
        </p>
      )}
    </Container>
  );
}

export default Profile;
