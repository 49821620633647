import { useState, useCallback } from "react";

const localStorageItem = "dataV0";

function load(stateName) {
  return JSON.parse(localStorage.getItem(localStorageItem))?.[stateName];
}

export function getData() {
  return JSON.parse(localStorage.getItem(localStorageItem)) || {};
}

function persist(stateName, state) {
  const data = getData();
  data[stateName] = state;

  localStorage.setItem(localStorageItem, JSON.stringify(data));
}

export function usePersistedState(stateName, initialState) {
  const [state, setState] = useState(() => {
    const current = load(stateName);
    if (current) {
      return current;
    }
    persist(stateName, initialState);
    return initialState;
  });

  const persistedSetState = useCallback(
    (state) => {
      if (typeof state === "function") {
        console.error("Functional setter not supported for peristed state.");
      }
      // Call setState as first thing to improve UI responsiveness
      setState(state);
      persist(stateName, state);
    },
    [stateName]
  );

  return [state, persistedSetState];
}
