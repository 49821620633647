import clsx from "clsx";
import { forwardRef, useCallback } from "react";
import { Link } from "react-router-dom";

import { useUser } from "../../services/DbService";

import tapSound from "../../assets/sounds/tap.mp3";

import styles from "./Button.module.scss";

export const ButtonColors = {
  ACCENT: "accent",
  LIGHT_OUTLINE: "lightOutline",
};

export const ButtonSizes = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const Button = forwardRef(
  (
    {
      className,
      children,
      block,
      loading,
      disabled,
      color = ButtonColors.ACCENT,
      size = ButtonSizes.MEDIUM,
      backdropBlur = true,
      to,
      sound = tapSound,
      onClick,
      iconOnly,
      ...props
    },
    ref
  ) => {
    const { audio } = useUser();

    let ButtonTag = "button";
    const buttonProps = {};

    if (to) {
      ButtonTag = Link;
      buttonProps.to = to;
    }

    const handleClick = useCallback(
      (e) => {
        sound && audio.sfx && new Audio(sound).play();
        audio.haptic && navigator.vibrate(20);
        onClick && onClick(e);
      },
      [onClick, sound, audio]
    );

    return (
      <ButtonTag
        className={clsx(
          className,
          styles.Button,
          styles[`Button-${color}Color`],
          styles[`Button-${size}Size`],
          block && styles["Button-block"],
          loading && styles["Button-loading"],
          backdropBlur && styles["Button-backdropBlur"],
          iconOnly && styles["Button-iconOnly"]
        )}
        disabled={disabled || loading}
        onClick={handleClick}
        {...buttonProps}
        {...props}
        ref={ref}
      >
        <span className={styles.Children}>{children}</span>
      </ButtonTag>
    );
  }
);

export default Button;
