import clsx from "clsx";

import styles from "./Card.module.scss";

function Card({ className, children, tag = "div", center, ...props }) {
  const CardTag = tag;
  return (
    <CardTag
      className={clsx(className, styles.Card, center && styles["Card-center"])}
      {...props}
    >
      {children}
    </CardTag>
  );
}

export default Card;
