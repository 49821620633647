import { getMany, set, update, clear } from "idb-keyval";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { USER_PLACEHOLDER_NAMES } from "../constants/user";

import { randomItemFromArray } from "../utils/randomItemFromArray";

import successSound from "../assets/sounds/success.mp3";
import failureSound from "../assets/sounds/failure.mp3";

const POINTS_PER_CORRECT_ANSWER = 10;
const POINTS_FOR_FIRST_GUESS = 5;

const POINTS_COUNT = "points";
const CORRECT_COUNT = "correct";
const INCORRECT_COUNT = "incorrect";
const STREAK_COUNT = "streak";
const STREAK_HISCORE = "streakHiscore";

export const AudioEnum = {
  MUSIC: "audioMusic",
  SFX: "audioSfx",
  HAPTIC: "audioHaptic",
};

export async function getUser() {
  let [id, name] = await getMany(["id", "name"]);

  if (!id) {
    id = uuidv4();
    set("id", id);
  }

  if (!name) {
    name = randomItemFromArray(USER_PLACEHOLDER_NAMES);
    set("name", name);
  }

  const [
    points,
    correct,
    incorrect,
    streak,
    streakHiscore,
    audioSfx,
    audioHaptic,
  ] = await getMany([
    POINTS_COUNT,
    CORRECT_COUNT,
    INCORRECT_COUNT,
    STREAK_COUNT,
    STREAK_HISCORE,
    AudioEnum.SFX,
    AudioEnum.HAPTIC,
  ]);

  const user = {
    id,
    name,
    [POINTS_COUNT]: points || 0,
    [CORRECT_COUNT]: correct || 0,
    [INCORRECT_COUNT]: incorrect || 0,
    [STREAK_COUNT]: streak || 0,
    [STREAK_HISCORE]: streakHiscore || 0,
    audio: {
      music: false, // Not yet supported
      sfx: audioSfx ?? true,
      haptic: navigator.vibrate && (audioHaptic ?? true),
    },
  };

  return user;
}

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  async function updateUser() {
    setUser(await getUser());
  }

  useEffect(() => {
    updateUser();
  }, []);

  const value = useMemo(() => {
    return { user, setUser, updateUser };
  }, [user, setUser]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUser() {
  const { user } = useContext(UserContext);
  return user;
}

export function getStreakMultiplier(streak) {
  if (streak >= 100) return 6;
  if (streak >= 50) return 5;
  if (streak >= 25) return 4;
  if (streak >= 10) return 3;
  if (streak >= 5) return 2;
  return 1;
}

export function useSetCorrectAnswer() {
  const { updateUser } = useContext(UserContext);

  return async (isFirstGuess) => {
    const [audioSfx = true, audioHaptic = true] = await getMany([
      AudioEnum.SFX,
      AudioEnum.HAPTIC,
    ]);
    audioSfx && new Audio(successSound).play();
    audioHaptic && navigator.vibrate(20);

    let streak;
    await update(STREAK_COUNT, (val) => {
      streak = (val || 0) + 1;
      return streak;
    });
    await update(POINTS_COUNT, (currentPoints = 0) => {
      let newPoints = currentPoints;
      newPoints += POINTS_PER_CORRECT_ANSWER * getStreakMultiplier(streak);
      if (isFirstGuess) {
        newPoints += POINTS_FOR_FIRST_GUESS;
      }
      return newPoints;
    });
    await update(CORRECT_COUNT, (currentCorrect = 0) => {
      return currentCorrect + 1;
    });
    await update(STREAK_HISCORE, (currentStreakHiscore = 0) => {
      return Math.max(streak, currentStreakHiscore);
    });

    updateUser();
  };
}

export function useSetIncorrectAnswer() {
  const { updateUser } = useContext(UserContext);

  return async () => {
    const [audioSfx = true, audioHaptic = true] = await getMany([
      AudioEnum.SFX,
      AudioEnum.HAPTIC,
    ]);
    audioSfx && new Audio(failureSound).play();
    audioHaptic && navigator.vibrate(20);

    await set(STREAK_COUNT, 0);
    await update(INCORRECT_COUNT, (currentIncorrect = 0) => {
      return currentIncorrect + 1;
    });

    updateUser();
  };
}

export function useIncrementPoints() {
  const { updateUser } = useContext(UserContext);

  return async (numberToAdd) => {
    await update(POINTS_COUNT, (currentPoints = 0) => {
      return currentPoints + numberToAdd;
    });

    updateUser();
  };
}

export function useResetUser() {
  const { updateUser } = useContext(UserContext);

  return async () => {
    await clear();

    updateUser();
  };
}

export function useToggleAudio() {
  const { updateUser } = useContext(UserContext);

  return async (audioType) => {
    await update(audioType, (currentSetting = true) => {
      return !currentSetting;
    });

    updateUser();
  };
}
